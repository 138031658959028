import {
  Avatar,
  Col,
  List,
  Modal,
  Row,
  Spin,
  Table,
  TableProps,
  Typography,
} from "antd";
import ProjectDetail from "./ProjectCards/ProjectDetail";
import "../../common/common.css";
import PendingTask from "./PendingTask/PendingTask";
import { Suspense, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { userData } from "../../store/slices/user-slice";
import { Link } from "react-router-dom";
import { projectData, setProjectData } from "../../store/slices/project-slice";
import { LoadingOutlined, UserOutlined } from "@ant-design/icons";
import TableSkeleton from "./Common/TableSkeleton";
import { getAvatarUrl, isUserAdmin, isUserTeamLeader } from "../../utils/Util";
import { AppRoutesPath } from "../../Routes/AppRoutesPath";
import ProjectTimeline from "../../common/ProjectTimeline";
import AssigneeContribution from "../../components/commoncomponents/AssigneeContribution";
import HighlyActiveProject from "../../components/commoncomponents/HighlyActiveProject";
import TaskReport from "../Taskreportdashbord/TaskReport";
const Dashboard = (props: any) => {
  const user = useSelector(userData);
  const project = useSelector(projectData);
  const [selectedProject, setSelectedProject] = useState<{
    label: string;
    value: string;
  }>({ label: project?.name, value: project?.id });

  const [isLoading, setIsLoading] = useState(false);
  const [show, setShow] = useState<string>("");
  const [shouldRefresh, setShouldRefresh] = useState<boolean>(false);
  const [selectedAssignees, setSelectedAssignees] = useState([]);
  const dispatch = useDispatch();
  const columns = [
    {
      title: "S. No.",
      dataIndex: "serial",
      key: "serial",
      width: "4%",
      render: (text: string, record: any, index: number) => (
        <div className="pl-4">{text}</div>
      ),
    },
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      //  showSorterTooltip: false,
      width: "20%",
      // sorter: (a: any, b: any) => a.name.localeCompare(b.name),
      render: (item: any, record: any) => (
        <List itemLayout="horizontal" size="small">
          <List.Item
            key={record?.id}
            style={{ padding: 0, alignItems: "center" }}
          >
            <List.Item.Meta
              style={{ padding: 0, alignItems: "center" }}
              key={`key-${record?.id}`}
              avatar={
                <Avatar
                  icon={<UserOutlined />}
                  size={"small"}
                  src={getAvatarUrl(record)}
                  alt="user"
                />
              }
              title={
                <Link
                  to={`/${AppRoutesPath?.reports?.home}/${AppRoutesPath?.reports?.userReport}?userid=${record?.id}`}
                  state={{ userid: record?.id }}
                >
                  <Typography.Paragraph
                    title={record?.name}
                    ellipsis
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      fontWeight: "normal",
                    }}
                  >
                    {record?.name}
                  </Typography.Paragraph>
                </Link>
              }
            />
          </List.Item>
        </List>
      ),
    },
  ];
  // pagination code
  const [listParams, setListParams] = useState({
    start: 0,
    length: 7,
  });
  const [refresh, setRefresh] = useState<boolean>(false);
  const changeListParams = (key: string, value: any) => {
    setListParams((p: any) => {
      p[key] = value;
      return p;
    });

    //setListParams((x: any) => ({ ...x }));
  };
  const onChangeTable: TableProps<any>["onChange"] = (paging: any) => {
    if (paging.current !== undefined && paging.pageSize !== undefined) {
      changeListParams("start", (paging.current - 1) * paging.pageSize);
      changeListParams("length", paging.pageSize);
      setRefresh(!refresh);
    }
  };
  const pageSizeOption = ["7", "10", "15", "20", "40"];
  useEffect(() => {
    if (!!selectedProject?.value) {
      dispatch(
        setProjectData({
          id: selectedProject?.value,
          name: selectedProject?.label,
        })
      );
    }
  }, [selectedProject]);
  return (
    <>
      <Spin
        spinning={isLoading}
        indicator={<LoadingOutlined spin />}
        size="default"
      >
        <div>
          <Row gutter={[0, 0]} justify={"space-between"}>
            <Col
              md={24}
              lg={24}
              xl={17}
              xs={17}
              xxl={18}
              className="capTask-custom-col  mt-3"
              style={{ background: "#f3f4fa" }}
            >
              <div
                style={{
                  overflowY: "auto",
                  overflowX: "hidden",
                  borderRadius: 12,
                }}
              >
                <Col
                  // lg={16}
                  // xl={24}
                  // xxl={24}
                  // md={24}
                  // sm={24}
                  // xs={24}
                  lg={24}
                  xl={24}
                  xs={24}
                  xxl={24}
                  className="ml-3"
                >
                  <Row>
                    <Col span={24} className="mb-2">
                      <ProjectDetail
                        setShow={setShow}
                        setSelectedAssignees={setSelectedAssignees}
                      />
                    </Col>
                  </Row>
                  <Row gutter={[0, 0]} justify={"space-between"}>
                    <Col xl={12} md={24} sm={24}>
                      <TaskReport
                        project={selectedProject}
                        onProjectChange={(pro) => {
                          setSelectedProject((pre: any) => ({
                            ...pre,
                            label: pro?.name,
                            value: pro?.id,
                          }));
                        }}
                      />
                    </Col>
                    <Col
                      xl={12}
                      md={24}
                      sm={24}
                      className="board-rounded-card mt-0"
                    >
                      <PendingTask
                        page="PendingTask"
                        props={undefined}
                        projectdata={undefined}
                        setShow={setShow}
                        setSelectedAssignees={setSelectedAssignees}
                      />
                    </Col>
                  </Row>

                  <Row gutter={[0, 0]} justify={"space-between"}>
                    <Col xl={12} md={24} sm={24}>
                      <AssigneeContribution
                        projectId={selectedProject?.value}
                      />
                    </Col>
                    <Col
                      xl={12}
                      md={24}
                      sm={24}
                      className="board-rounded-card mt-2 "
                    >
                      <HighlyActiveProject />
                    </Col>
                  </Row>
                </Col>
              </div>
            </Col>
            <Col
              // xs={24}
              // sm={24}
              // md={24}
              lg={24}
              xl={6}
              xs={24}
              xxl={6}
              // span={5}
              style={{
                marginTop: "8px",
                // height: "85vh",
                backgroundColor: "white",
                padding: "14px",
                borderRadius: 12,
                overflow: "auto",
              }}
            >
              <Suspense>
                <ProjectTimeline
                  getAll
                  projectId={""}
                  assigneeId={
                    isUserAdmin()  ? "" : user?.id
                  }
                  hideSelect
                  vHeight={680}
                />
              </Suspense>
            </Col>
          </Row>
        </div>

        {show === "Assignees" && (
          <Modal
          width={600}
            title="Assignee List"
            open={!!show}
            onCancel={() =>
              setTimeout(() => {
                setShow("");
              }, 100)
            }
            footer={null}
          >
            {isLoading ? (
              <TableSkeleton
                columns={columns}
                scroll={{ y: "75vh" }}
                style={{
                  scrollbarWidth: "none",
                  borderTop: "solid 1px #b2bec3",
                }}
              />
            ) : (
              <Table
                className="capTask-custom-item"
                scroll={{ y: "255px" }}
                style={{
                  border: "1px solid #dfe6e9",
                  borderRadius: 3,
                  padding: "12px 7px",
                }}
                onChange={onChangeTable}
                pagination={{
                  showTotal: (total: any, range: any) =>
                    `${range[0]}-${range[1]} of ${total} items`,
                  current: listParams.start / listParams.length + 1,
                  pageSize: listParams.length,
                  showSizeChanger: true,
                  pageSizeOptions: pageSizeOption,
                  position: ["bottomRight"],
                }}
                dataSource={selectedAssignees?.map(
                  (data: any, index: number) => ({
                    serial: index + 1,
                    key: index,
                    ...data,
                  })
                )}
                columns={columns}
                rowKey="id"
              />
            )}
          </Modal>
        )}
      </Spin>
    </>
  );
};

export default Dashboard;
