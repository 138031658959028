import React, { useEffect, useMemo, useState } from "react";

import { Avatar, Button, Card, Col, Input, Row, Typography } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { userData } from "../store/slices/user-slice";
import {
  CameraOutlined,
  ClusterOutlined,
  EnvironmentOutlined,
  InsertRowRightOutlined,
  MailOutlined,
  PlusCircleOutlined,
  TagOutlined,
  TeamOutlined,
  UserOutlined,
} from "@ant-design/icons";
import {
  projectData as PD,
  setProjectData,
} from "../store/slices/project-slice";
import UploadComponent from "./UploadAvatar";
import { useGetProjects } from "../Services/RQDashboardService";
import { Link, useNavigate } from "react-router-dom";
import { getEllipsisText } from "../utils/Util";

const Profile = ({  ProfilePadding = "0px 0px",  }: any) => {
  const { isLoading, data: projectData } = useGetProjects();
  const project = useSelector(PD);
  const navigate = useNavigate();
  const [isUpload, setIsUpload] = useState<boolean>(false);
  const [showPopup, setShowPopup] = useState(false);
  const dispatch = useDispatch();
  const [isEditingTitle, setIsEditingTitle] = useState(false);
  const [jobTitle, setJobTitle] = useState("Your job title");
  const handleEditClick = () => {
    setIsEditingTitle(true);
  };
  const handleSaveClick = () => {
    setIsEditingTitle(false);
  };
  const closeUpload = () => setIsUpload(false);
  const user = useSelector(userData);
  const openUpload = () => setIsUpload(true);

  const getProjectsList = useMemo(() => {
    if (projectData?.result && projectData?.result?.length > 0) {
      if (!project?.id) {
        dispatch(
          setProjectData({
            id: projectData.result[0]?.id,
            name: projectData.result[0]?.name,
          })
        );
      }
      return projectData?.result?.map((project: any) => ({
        ...project,
        imagePath:
          project?.imagePath === "?t=" ? undefined : project?.imagePath,
          
      }));
    }
    return [];
  }, [projectData]);

  useEffect(() => {
    if (getProjectsList?.length > 0) setRes(getProjectsList);
  }, [getProjectsList]);
  const [res, setRes] = useState([1, 2, 3, 4, 5]);
  const handleClick = ({
    projectId,
    projectName,
  }: {
    projectId: string;
    projectName: string;
  }) => {
    dispatch(
      setProjectData({
        id: projectId,
        name: projectName,
      })
    );
    navigate("/board");
  };
  return (
    <>
      <Row style={{padding:ProfilePadding}}>
        <Col
          span={24}
          style={{ backgroundColor: "skyblue", height: 150, padding: 0 }}
        >
          <div
            className="avatarWrapper"
            onClick={() => {
              openUpload();
              setShowPopup(!showPopup);
              
            }}
          >
            <Avatar
              className="profileAvatar"
              size={130}
              src={user?.imageThumb}
            />
            <div className="cameraIcon">
              <CameraOutlined />
            </div>
          </div>
        </Col>
        {isUpload && (
          <UploadComponent
            isUpload={isUpload}
            closeUpload={closeUpload}
            setIsUpload={setIsUpload}
          />
        )}
      </Row>

      <Row
        justify="space-between"
        // style={{ textAlign: "center" }}

        className="mt-15"
        gutter={[16, 16]}
      >
        <Col xs={24} sm={24} md={8} lg={8} className="ProfileCss">
      

          {user?.name}
          <Col className="mt-10">
            <button
              className="Account"
              style={{
                width: "100%",
                maxWidth: "281px",
                height: "30px",
                marginRight: "0",
                borderRadius: "3px",
                backgroundColor: "rgba(9, 30, 66, 0.04)",
                paddingRight: 15,
                border: "0",
                cursor: "pointer",
              }}
            >
              <span style={{ fontWeight: "revert" }}> Manage your account</span>
            </button>
            <Col
              xs={24}
              sm={24}
              md={8}
              lg={8}
              style={{ textAlign: "left", marginTop: "16px" }}
            >
              <Card
                title={
                  <span style={{ color: "#636e72", fontSize: "13px" }}>
                    ABOUT
                  </span>
                }
                bordered={false}
                style={{
                  // width: "100%",
                  width: "300px",
                  // margin: "23px auto",
                }}
              >
                <Row className="pl-5">
                  <Col span={24} className="mb-4">
                    <UserOutlined
                      style={{ fontSize: 17, color: "#7A869A" }}
                      className="mr-1"
                    />
                    {isEditingTitle ? (
                      <Input
                        value={jobTitle}
                        onChange={(e) => setJobTitle(e?.target?.value)}
                        style={{
                          width: "100%",
                          maxWidth: "200px",
                          marginLeft: "8px",
                        }}
                      />
                    ) : (
                      <span
                        style={{
                          marginLeft: "8px",
                          fontSize: 13,
                          color: "#7A869A",
                        }}
                      >
                        {jobTitle}
                      </span>
                    )}
                    {isEditingTitle ? (
                      <Button type="link" onClick={handleSaveClick}>
                        Save
                      </Button>
                    ) : (
                      <Button type="link" onClick={handleEditClick}>
                        Edit
                      </Button>
                    )}
                  </Col>

                  <Col span={24} className="mb-6">
                    <ClusterOutlined
                      className="mr-2"
                      style={{ fontSize: 18, color: "#7A869A" }}
                    />
                    <span style={{ fontSize: 13, color: "#7A869A" }}>
                      Your department
                    </span>
                  </Col>

                  <Col span={24} className="mb-6">
                    <InsertRowRightOutlined
                      className="mr-2"
                      style={{ fontSize: 18, color: "#7A869A" }}
                    />
                    <span style={{ fontSize: 13, color: "#7A869A" }}>
                      Your organization
                    </span>
                  </Col>

                  <Col span={24} className="mb-6">
                    <EnvironmentOutlined
                      className="mr-2"
                      style={{ fontSize: 18, color: "#7A869A" }}
                    />
                    <span style={{ fontSize: 13, color: "#7A869A" }}>
                      Your location
                    </span>
                  </Col>
                  <span style={{ color: "#636e72", fontSize: "13px" }}>
                    CONTACT
                  </span>
                  <Col span={24} className="mb-6 mt-4">
                    <MailOutlined
                      className="mr-2 "
                      style={{
                        fontSize: 18,
                        color: "#7A869A",
                        margin: "5px 0px",
                      }}
                    />
                    <span style={{ fontSize: 13, color: "#7A869A" }}>
                      {user?.email}
                    </span>
                  </Col>
                  <span style={{ color: "#636e72", fontSize: "13px" }}>
                    TEAMS
                  </span>
                  <Col span={24} className="mb-6 mt-4">
                    <PlusCircleOutlined
                      disabled
                      className="mr-2 "
                      style={{
                        fontSize: 18,
                        color: "#7A869A",
                        margin: "5px 0px",
                      }}
                    />
                    <Button type="text" className="mr-3" disabled>
                      <span style={{ fontSize: 13, color: "#7A869A" }}>
                        Create a team
                      </span>
                    </Button>
                  </Col>

                  <Col span={24} className="mb-6">
                    <Typography.Link
                      href="/PrivacyPolicy.html"
                      target="_blank"
                      className="pl-1"
                      style={{
                        fontSize: 12,
                        color: "#7A869A",
                        textDecoration: "underline #7A869A",
                      }}
                    >
                      View privacy policy
                    </Typography.Link>
                  </Col>
                </Row>
              </Card>
            </Col>
          </Col>
    

      
        </Col>

        <Col xs={24} sm={24} md={8} lg={14}>

         <Row>
        <Col
            style={{
              textAlign: "left",
              marginLeft: "129px",
              marginTop: "-35px",
            }}
          >
            <span style={{ color: "#172B4D", fontWeight: 600 }}>Worked on</span>
          </Col>
          <Col
            style={{
              fontSize: 13,
              color: "#7A869A",
              textAlign: "left",
              marginLeft: "129px",
            }}
          >
            Others will only see what they can access.
            <Button type="link" style={{ margin: "0px 320px" }}>
              View all
            </Button>
          </Col>

          <Card
            title={<span style={{ color: "#636e72", fontSize: "13px" }}></span>}
            bordered={true}
            style={{
              width: 640,
              height: 300,
              marginLeft: "129px",
              marginTop: "15px",
              borderRadius: 5,
            }}
          ></Card>
          <Col>
            <Row justify={"space-between"} align={"middle"}>
              <Col
                style={{
                  textAlign: "left",
                  marginLeft: "129px",
                  marginTop: "35px",
                }}
                span={3}
              >
                <span style={{ color: "#172B4D", fontWeight: 600 }}>
                  Places you work in
                </span>
              </Col>
              <Col
                span={17}
                style={{ marginTop: "19px", marginBottom: "-11px" }}
              >
                <Link
                  style={{
                    fontSize: "14px",
                    fontFamily: "Open Sans",
                    fontWeight: 400,
                    color: "#253148",
                    textShadow: " 0 0 0.25px currentcolor",
                  }}
                  to="/viewall"
                >
                  <Button type="link">View all</Button>
                </Link>
              </Col>
            </Row>
            <Col></Col>
            <Card
              loading={isLoading}
              title={
                <span style={{ color: "#636e72", fontSize: "13px" }}></span>
              }
              bordered={true}
              style={{
                width: 640,
                height: 100,
                marginLeft: "129px",
                marginTop: "15px",
                borderRadius: 5,
                marginBottom: "-18px",
              }}
            >
              <Row justify="space-between" gutter={[20, 20]}>
                <Col
                  span={24}
                  style={{
                    display: "flex",
                    flexDirection: "row-reverse",
                  }}
                >
                  {res?.slice(0, 3).map((item: any, index: number) => (
                    <Row
                      key={index}
                      justify="space-between"
                      gutter={[20, 20]}
                      align="middle"
                      style={{ width: "100%" }} // Ensure it takes full width
                    >
                      <Col span={24}>
                        <Avatar
                          size={30}
                          className="mr-2"
                          shape="circle"
                          style={{
                            border: "1px solid rgb(228, 232, 240)",
                            backgroundColor: "white",
                            borderRadius: 10,
                            fontSize: 14,
                            cursor: "pointer",
                          }}
                        >
                          <span
                            onClick={() =>
                              handleClick({
                                projectId: item?.id,
                                projectName: item?.name,
                              })
                            }
                          >
                            {item?.alias}
                          </span>
                        </Avatar>
                        <span> {getEllipsisText(item?.name, 20)}</span>
                      </Col>
                    </Row>
                  ))}
                </Col>
              </Row>
            </Card>
          </Col>
          <Col>
            <Col
              style={{
                textAlign: "left",
                marginLeft: "129px",
                marginTop: "35px",
              }}
            >
              <span style={{ color: "#172B4D", fontWeight: 600 }}>
                Works with
              </span>
            </Col>
            <Col
              style={{
                textAlign: "left",
                marginLeft: "133px",
                marginTop: "6px",
              }}
            >
              <Button
                style={{ backgroundColor: "#DEEBFF" }}
                type="text"
                shape="round"
                icon={<TeamOutlined />}
              >
                Collaborators
              </Button>
            </Col>
            <Card
              title={
                <span style={{ color: "#636e72", fontSize: "13px" }}></span>
              }
              bordered={true}
              style={{
                width: 640,
                height: 150,
                marginLeft: "129px",
                marginTop: "15px",
                borderRadius: 5,
              }}
            >
              <Row justify="space-between" align="middle">
                <Col style={{ margin: "-27px 22px" }} span={4}>
                  <Avatar.Group>
                    <Avatar
                      style={{ backgroundColor: "#87d068" }}
                      size={70}
                      icon={<img src="/Oliver_ptc.da3d9664.svg" alt="Oliver" />}
                    />
                    <Avatar
                      style={{ backgroundColor: "#87d068" }}
                      size={70}
                      icon={<img src="/Effie_ptc.98fa899a.svg" alt="Effie" />}
                    />
                  </Avatar.Group>
                </Col>
                <Col
                  span={20}
                  style={{
                    margin: "-36px 104px",
                  }}
                >
                  <h3>There are no people to see here</h3>
                  <p className="p-0">
                    People you collaborated with in the last 90 days will show
                    here.
                  </p>
                </Col>
              </Row>
            </Card>
          </Col> 
         </Row>
        </Col>
      </Row>

      {/* <Row>
        <Col span={24} style={{ textAlign: "center", margin: "0px 76px" }}>
          <Typography.Text
            className="mt-2 "
            style={{ fontSize: "13px" }}
            ellipsis
          >
            Tell us about your experience with profiles and search within this
            directory.
          </Typography.Text>
        </Col>
      </Row> */}
    </>
  );
};

export default Profile;
