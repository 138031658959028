import React, { useEffect, useMemo, useState } from "react";

import { Avatar, Button, Card, Col, Modal, Rate, Row, Typography } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { userData } from "../store/slices/user-slice";
import {
  CameraOutlined,
  CheckSquareTwoTone,
  ClusterOutlined,
  EnvironmentOutlined,
  InsertRowRightOutlined,
  MailOutlined,
  PlusCircleOutlined,
  StarFilled,
  TeamOutlined,
} from "@ant-design/icons";
import {
  projectData as PD,
  setProjectData,
} from "../store/slices/project-slice";
import UploadComponent from "./UploadAvatar";
import {
  useGetPendingTasks,
  useGetProjects,
} from "../Services/RQDashboardService";
import { Link, useNavigate } from "react-router-dom";
import { getEllipsisText } from "../utils/Util";
import "../common/common.css";
import HTTPSCalls from "../Services/HTTPCalls";
import moment from "moment";

const Profile = () => {
  const { isLoading, data: projectData } = useGetProjects();
  const project = useSelector(PD);
  const navigate = useNavigate();
  const [isUpload, setIsUpload] = useState<boolean>(false);
  const [showPopup, setShowPopup] = useState(false);
  const dispatch = useDispatch();
  const [isEditingTitle, setIsEditingTitle] = useState(false);
  const [jobTitle, setJobTitle] = useState("Your job title");
  const handleEditClick = () => {
    setIsEditingTitle(true);
  };
  const user = useSelector(userData);

  const { data: taskData, isFetching: isTaskLoading } = useGetPendingTasks(
    { assigneeId: user?.id },
    user?.id
  );
  const getPendingTaskList = useMemo(() => {
    return taskData?.result?.items?.map((task: any) => ({
      ...task,
      assignee: task?.assignee?.map((itm: any) => ({
        ...itm,
        thumb: itm?.imageThumbPath,
      })),
    }));
  }, [taskData]);

  const firstPendingTask = getPendingTaskList?.[0];
  const handleSaveClick = () => {
    setIsEditingTitle(false);
  };
  const closeUpload = () => setIsUpload(false);
  const openUpload = () => setIsUpload(true);

  const getProjectsList = useMemo(() => {
    if (projectData?.result && projectData?.result?.length > 0) {
      if (!project?.id) {
        dispatch(
          setProjectData({
            id: projectData.result[0]?.id,
            name: projectData.result[0]?.name,
          })
        );
      }
      return projectData?.result?.map((project: any) => ({
        ...project,
        imagePath:
          project?.imagePath === "?t=" ? undefined : project?.imagePath,
      }));
    }
    return [];
  }, [projectData]);

  useEffect(() => {
    if (getProjectsList?.length > 0) setRes(getProjectsList);
  }, [getProjectsList]);
  const [res, setRes] = useState([1, 2, 3, 4, 5]);

  const handleClick = ({
    projectId,
    projectName,
  }: {
    projectId: string;
    projectName: string;
  }) => {
    dispatch(
      setProjectData({
        id: projectId,
        name: projectName,
      })
    );
    navigate("/board");
  };
  const getStars = (role: any) => {
    const starCount = role === "Admin" ? 3 : role === "TeamLeader" ? 2 : 0;
    return Array.from({ length: starCount }, (_, index) => (
      <StarFilled key={index} style={{ color: "#fadb14", marginRight: 4 }} />
    ));
  };
  const [visible, setVisible] = useState(false);
  const [rating, setRating] = useState(0);

  const handleOpen = () => {
    setVisible(true);
  };

  const handleClose = () => {
    setVisible(false);
  };
  return (
    <>
      <Row>
        <Col
          span={24}
          style={{ backgroundColor: "#dde8fa", height: 150, padding: 0 }}
        >
          <div
            className="avatarWrapper"
            onClick={() => {
              openUpload();
              setShowPopup(!showPopup);
            }}
          >
            <Avatar
              className="profileAvatar"
              size={130}
              src={user?.imageThumb}
            />
            <div className="cameraIcon">
              <CameraOutlined />
            </div>
          </div>
        </Col>
        {isUpload && (
          <UploadComponent
            isUpload={isUpload}
            closeUpload={closeUpload}
            setIsUpload={setIsUpload}
          />
        )}
      </Row>

      <Row justify="center" className="mt-8" gutter={[16, 16]}>
        <Col xs={24} sm={24} md={8} lg={8} xl={5} xxl={4}>
          <Row className="mt-5 mb-2" justify={"start"}>
            <Col>
              <Typography.Title level={4} ellipsis>
                {user?.name}
              </Typography.Title>
              <Typography.Text underline className="fs13">
                {user?.role}
              </Typography.Text>
              <span style={{ marginLeft: 8 }} className="fs12">
                {getStars(user?.role)}
              </span>
            </Col>
          </Row>
          <Row justify={"end"}>
            <button
              className="Account mb-2"
              style={{
                width: "100%",
                maxWidth: "281px",
                height: "30px",
                marginRight: "0",
                borderRadius: "3px",
                backgroundColor: "rgba(9, 30, 66, 0.04)",
                paddingRight: 15,
                border: "0",
                cursor: "pointer",
              }}
            >
              <span
                style={{
                  fontWeight: "revert",
                  display: "block",
                  maxWidth: "100%",
                  whiteSpace: "nowrap",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                }}
              >
                Manage your account
              </span>
            </button>
          </Row>
          <Row justify={"end"}>
            <Card
              title={
                <span style={{ color: "#636e72", fontSize: "13px" }}>
                  ABOUT
                </span>
              }
              bordered={false}
              style={{
                width: "100%",
              }}
              // className="CardResponsive"
            >
              <Row className="pl-5">
                <Col span={24} className="mb-6 mt-2">
                  <ClusterOutlined
                    className="mr-2"
                    style={{ fontSize: 18, color: "#7A869A" }}
                  />
                  <span style={{ fontSize: 13, color: "#7A869A" }}>
                    {/* Your department */}
                    Information Technology (IT)
                  </span>
                </Col>

                <Col span={24} className="mb-6">
                  <InsertRowRightOutlined
                    className="mr-2"
                    style={{ fontSize: 18, color: "#7A869A" }}
                  />
                  <span style={{ fontSize: 13, color: "#7A869A" }}>
                    {/* Your organization */}
                    Capsitech.com
                  </span>
                </Col>

                <Col span={24} className="mb-6">
                  <EnvironmentOutlined
                    className="mr-2"
                    style={{ fontSize: 18, color: "#7A869A" }}
                  />
                  <span style={{ fontSize: 13, color: "#7A869A" }}>
                    H-299, 3rd Phase RIICO Industrial Area, Boranada, Rajasthan
                    342012
                  </span>
                </Col>
                <span style={{ color: "#636e72", fontSize: "13px" }}>
                  CONTACT
                </span>
                <Col span={24} className="mb-6 mt-4">
                  <MailOutlined
                    className="mr-2"
                    style={{
                      fontSize: 18,
                      color: "#7A869A",
                      margin: "5px 0px",
                    }}
                  />
                  <span
                    style={{ fontSize: 13, color: "#7A869A" }}
                    className="mb-1"
                  >
                    {user?.email}
                  </span>
                </Col>
                <span style={{ color: "#636e72", fontSize: "13px" }}>
                  TEAMS
                </span>
                <Col span={24} className="mb-6 mt-4">
                  <PlusCircleOutlined
                    disabled
                    className="mr-2 "
                    style={{
                      fontSize: 18,
                      color: "#7A869A",
                      margin: "5px 0px",
                    }}
                  />

                  <Button type="text" className="mr-3" disabled>
                    <span style={{ fontSize: 13, color: "#7A869A" }}>
                      Create a team
                    </span>
                  </Button>
                </Col>

                <Col span={24} className="mb-6">
                  <Typography.Link
                    href="/PrivacyPolicy.html"
                    target="_blank"
                    className="pl-1"
                    style={{
                      fontSize: 12,
                      color: "#7A869A",
                      textDecoration: "underline #7A869A",
                    }}
                  >
                    View privacy policy
                  </Typography.Link>
                </Col>
              </Row>
            </Card>
          </Row>
        </Col>

        <Col xs={24} sm={24} md={8} lg={12}>
          <Row className="mt-2">
            <span style={{ color: "#172B4D", fontWeight: 600 }}>Worked on</span>
          </Row>
          <Row justify={"space-between"}>
            <Col
              span={20}
              style={{
                fontSize: 13,
                color: "#7A869A",
                textAlign: "left",
              }}
            >
              Others will only see what they can access.
            </Col>
            <Col span={2}>
              <Link
                style={{
                  fontSize: "14px",
                  fontFamily: "Open Sans",
                  fontWeight: 400,
                  color: "#253148",
                  textShadow: " 0 0 0.25px currentcolor",
                }}
                to="/viewall"
              >
                <Button type="link">View all</Button>
              </Link>
            </Col>
          </Row>

          <Card
            title={<span className="cardheight"></span>}
            bordered={true}
            style={{
              width: "100%",
              height: 300,
              borderRadius: 5,
            }}
            className="mb-2"
          >
            {getPendingTaskList?.slice(0, 5).map((task: any, index: number) => (
              <Row key={index}>
                <Col
                  span={2}
                  style={{ textAlign: "center", cursor: "pointer" }}
                  className="mt-1"
                >
                  <CheckSquareTwoTone
                    onClick={() =>
                      handleClick({
                        projectId: task?.id,
                        projectName: task?.name,
                      })
                    }
                    style={{ fontSize: 26 }}
                  />
                </Col>
                <Col span={19}>
                  <Col className="fs13-5">
                    <Typography.Link>
                      <Typography.Text>
                        <span
                          style={{ color: "#1677ff" }}
                          onClick={() =>
                            handleClick({
                              projectId: task?.id,
                              projectName: task?.name,
                            })
                          }
                        >
                          {task?.name || "No Name"}
                        </span>
                      </Typography.Text>
                    </Typography.Link>
                  </Col>
                  <span className="fs13" style={{ color: "#8993A4" }}>
                    Task-{index + 1}
                  </span>
                  .
                  <span className="fs13" style={{ color: "#8993A4" }}>
                    You updated this on
                    <Typography.Text className="ml-2 fs13">
                      {task?.createDate
                        ? moment(task?.createDate).format("MMMM DD, YYYY")
                        : "N/A"}
                    </Typography.Text>
                  </span>
                </Col>
              </Row>
            ))}
            {getPendingTaskList && getPendingTaskList?.length > 3 && (
              <Col span={3} className="ml-2 mt-3">
                <Link
                  to={"/reports/issues"}
                  target="_blank"
                  className="pl-1"
                  style={{
                    fontSize: 13,
                    color: "#7A869A",
                    textDecoration: "underline #7A869A",
                    display: "block",
                    maxWidth: "100%",
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                  }}
                >
                  Show more
                </Link>
              </Col>
            )}
          </Card>

          <Row className="mb-2">
            <span style={{ color: "#172B4D", fontWeight: 600 }}>
              Works with
            </span>
          </Row>
          <Row className="mb-2">
            <Button
              style={{ backgroundColor: "#DEEBFF" }}
              type="text"
              shape="round"
              icon={<TeamOutlined />}
            >
              Collaborators
            </Button>
          </Row>

          <Card
            title={<span style={{ color: "#636e72", fontSize: "13px" }}></span>}
            bordered={true}
            style={{
              width: "100%",
              height: 150,
              borderRadius: 5,
            }}
          >
            <Row justify={"space-between"}>
              <Col span={4} className="ml-6">
                <Avatar.Group>
                  <Avatar
                    style={{ backgroundColor: "#87d068" }}
                    size={70}
                    icon={<img src="/Oliver_ptc.da3d9664.svg" alt="Oliver" />}
                  />
                  <Avatar
                    style={{ backgroundColor: "#87d068" }}
                    size={70}
                    icon={<img src="/Effie_ptc.98fa899a.svg" alt="Effie" />}
                  />
                </Avatar.Group>
              </Col>
              <Col span={17} className="mt-3">
                <h4
                  className="m-0"
                  style={{
                    display: "block",
                    maxWidth: "100%",
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                  }}
                >
                  There are no people to see here
                </h4>
                <p
                  className="m-0"
                  style={{
                    display: "block",
                    maxWidth: "100%",
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                  }}
                >
                  People you collaborated with in the last 90 days will show
                  here.
                </p>
              </Col>
            </Row>
          </Card>
          <Row className="mt-2 fs13">
            <Typography.Paragraph ellipsis className="fs13">
              Tell us about your experience with profiles and search within this
              directory.
            </Typography.Paragraph>
          </Row>

          <Row className="mt-2 mb-4 fs13">
            <button className="feedback" onClick={handleOpen}>
              <span>Send Capsitask feedback</span>
            </button>
          </Row>

          <Modal
            title="Your Feedback"
            open={visible}
            onCancel={handleClose}
            footer={null}
            width="30vw"
            bodyStyle={{
              height: "30vh",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <div style={{ textAlign: "center" }}>
              <h2>Rate Your Experience</h2>
              <Rate value={rating} onChange={setRating} />
              <p>Your rating: {rating} star(s)</p>
              <Button className="mt-3" onClick={handleClose}>
                Submit Feedback
              </Button>
            </div>
          </Modal>
        </Col>
      </Row>
    </>
  );
};

export default Profile;
